<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Настройка: Банера</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Список банеров</h5>
        </div>
      </div>
      <div class="card-body">
        <banners-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" />
      </div>
    </div>
  </div>
</template>

<script>
import BannersTable from '@/components/content/banners/BannersTable'

export default {
  name: 'index',
  data() {
    return {
      list: [],
      search: '',
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList(page = this.pagination.current) {
      const params = {
        page,
      }

      try {
        const banners = (await this.$services.get('admin/banners', { params })).data.data.values

        this.list = banners.data
        this.pagination = {
          current: banners.pagination.current_page,
          total: banners.pagination.total,
          pageSize: banners.pagination.per_page,
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
  },
  components: { BannersTable },
}
</script>

<style scoped>

</style>
